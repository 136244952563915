.RationalChoiceWrap {
  position: relative;
  overflow: hidden;
  background-color: #C1E7FA;

  h1 {
    width: 100%;
    @include media-breakpoint-up(md) {
      position: absolute;
      z-index: 1;
      margin-top: 10vh !important;
    }
  }

  .row.now-gutters {
    margin-top: 63px;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    .overborder {
      pointer-events: none;
      border: 1px solid #004687;
      border-top: 0;
      position: absolute;
      top: 10px;
      left: 20px;
      right: 20px;
      bottom: 10px;
      @include media-breakpoint-up(md) {
        border:0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      h1 {
        font-size: 3.2vw;
        position: absolute;
        max-width: 80%;
        text-align: center;
        top: -5px;
        left: 10%;
        right: 10%;
        @include media-breakpoint-up(md) {
          border:0;
          font-size: 3.2rem;
          left:0;
          right:0;
          max-width: 100%;
        }
      }

      .topLeft {
        border-top: 1px solid #004687;
        position: absolute;
        top: 0;
        left: 0;
        width: 8%;
        @include media-breakpoint-up(md) {
          border:0;
        }
      }

      .topRight {
        border-top: 1px solid #004687;
        position: absolute;
        top: 0;
        right: 0;
        width: 8%;
        @include media-breakpoint-up(md) {
          border:0;
        }
      }
    }
  }

  .choice {
    min-height: calc((100vh - 63px) / 3);
    background-color: $gray-200;
    background-size: cover;
    background-position: top center;

    padding-top: 0.8em;
    @include media-breakpoint-up(md) {
      padding-top: 0;
      height: 100vh;
    }

    &.choice-romantic {
      background-image: url(./media/bg-romantica-h.jpg);
      @include media-breakpoint-up(md) {
        background-image: url(./media/bg-romantica-v.jpg);
      }
    }
    &.choice-relaxing {
      background-image: url(./media/bg-rilassante-h.jpg);
      @include media-breakpoint-up(md) {
        background-image: url(./media/bg-rilassante-v.jpg);
      }
    }
    &.choice-exciting {
      background-image: url(./media/bg-divertente-h.jpg);
      @include media-breakpoint-up(md) {
        background-image: url(./media/bg-divertente-v.jpg);
      }
    }

    .btn {
      font-size: 1rem;
      padding: 0.2rem 1.5rem;
      @include media-breakpoint-up(md) {
        margin-bottom: 1em;
        font-size: 1rem;
        padding: 0.5rem 1rem;
      }
    }

    p {
      font-size: 1em;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 1.4em;
      margin-top: 5px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
        font-size: 1.4em;
        line-height: normal;
        font-weight: normal;
      }
    }

    > div {
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
      @include media-breakpoint-up(md) {
        margin-top: 32vh;
      }
    }
  }

  .st_choice{
    padding-top: 1.7em;
    @include media-breakpoint-up(md) {
      padding-top: 0;
    }
  }

  footer.page-footer .step-indicator span.indicator-1 {
    background-image: url(./media/indicator-active@2x.png);
  }
}