.StartTestWrap {
  background: url(./media/bg-1.jpg);
  @include media-breakpoint-up(lg) {
    background-image: url(./media/bg-1@2x.jpg);
  }

  background-size: cover;
  background-position: center bottom;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  img.logo-ragione {
    max-width: 60vw;
    height: auto;
    margin-bottom: 5vh;
    @include media-breakpoint-up(md) {
      max-width: 30vw;

    }
    @media (max-height: 1080px) {
      max-width: 40vh !important;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    color: white;
    font-style: italic;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 1em;
    @include media-breakpoint-up(md) {
    }
    @media (max-width: 768px){
      font-size: 1.5rem;
    }
  }

  p {
    color: white;

  }

  footer {
    img {
      max-width: 30vw;
      height: auto;
    }
  }

  &.ig-fb {
    img.logo-ragione {
      max-width: 50vw;
      height: auto;
      margin-bottom: 5vh;
      @include media-breakpoint-up(md) {
        max-width: 25vw;
      }
    }

  }

  footer.page-footer {
    position: static;
  }

  footer.page-footer .step-indicator {
    display: none;
  }

  .top-container {
    position: static;
  }
}