@import url("https://use.typekit.net/xxs8wmc.css");

$blue: #004687;
$red: #b51f2a;
$yellow: #ec6707;
$gray-100: #fff;
$body-color: $blue;
$enable-responsive-font-sizes: true;
$font-family-sans-serif: museo-sans, sans-serif;;
$font-weight-normal: 500;
$font-weight-bold: 900;

$font-size-base:              1rem;
$h1-font-size:                $font-size-base * 3.2 ;
/*
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;
*/
