@import "Variables";
.top-container {
  color: #fff;
  background: linear-gradient(rgba($blue, 0.5) 0%, rgba($blue, 0) 90%);
  border: none;
  margin-bottom: 0;
  position: absolute;
  top:0;
  z-index: 10;
}

.top-container img{
  padding:15px;
}