.btn {
	border-radius: 4rem !important;
	font-weight: bold;
	&.btn-light {
		color: $blue;
	}
	&:hover {
		background: $blue;
		border-color: $blue;
		color: white;
	}
}

h1 {
	font-weight: 900;
	font-style: italic;
	text-transform: uppercase;
}

footer.page-footer {
	background-color: $blue;
	@include media-breakpoint-up(md) {
		z-index: 100;
		position:absolute;
		margin: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		background: linear-gradient(rgba($blue, 0) 0%, rgba($blue, 0.5) 100%);
    }

	span{
		color:white;
	}
	
	.step-indicator {
		display: none;
		@include media-breakpoint-up(md) {
			display: block;
			margin-bottom: -10px;
    	}
		span {
			background: url(./media/indicator-blank@2x.png);
			background-size: 100% auto;
			background-position: center center;
			width:200px;
			max-width: 10vw;
			height: 60px;
			display: inline-block;
			overflow: hidden;
			text-indent: -9999px;
		}
	}
	
	
	a {
		color: $white;
		margin-left: 1rem;
		margin-right: 1rem; 
		margin-bottom: 1rem;
		display: inline-block;
		@include media-breakpoint-up(sm) {
			margin-left: 0;  	
			margin-bottom: 0;
    	}
    
	}
	img {
		width: 150px;
		height: auto;
		max-width: 20vw;
	}
}