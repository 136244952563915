.ResultWrap {
	background-size: cover;
	background-position: center center;
	color: $white;
	position: relative;
	
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($black, 0.15);
		z-index: 1;
	}
	.container-fluid {
		position: relative;
		z-index: 2;
		min-height: 100vh;
		padding-top: 1rem;
		@include media-breakpoint-up(md) {
			padding-top: 8vh;
		    padding-left: 5vw;
		    padding-right: 5vw;
		    padding-bottom: 8vh;
	    }
    }
    .decision-left {
		@include media-breakpoint-up(md) {
			text-align: right;	
			padding-left: 5vw;
	    }   
	    div {
		    border-bottom: solid 3px $white;
		    margin-bottom: 1rem;
		    @include media-breakpoint-up(md) {
			    padding-top: 90px;
			    //max-height: 200px;
				border-right: solid 3px $white;
				padding-right: 30px;
				padding-bottom: 10px;
				max-width: 300px;
				float: right;

			}
		} 
    }
    
     .decision-right {
		@include media-breakpoint-up(md) {
			div.decision-inner {
				max-width: 800px;
				padding-left: 30px;
				border-top: solid 3px $white;
				padding-top: 30px;
			}
	    }
    }
	
	p.h4 {
		margin-bottom: 1rem;
		@include media-breakpoint-down(sm) {
			font-size: 1.2rem;
		}

		@include media-breakpoint-up(md) {
			margin-bottom: 3rem;
		}
	}
	
	.logo-result {
		max-width: 35vw;
		height: auto;

		margin:0 5vw 0 0;
		@include media-breakpoint-up(sm) {
			max-width: 200px;
			margin: 0 0 1rem 0;
			display:  block;
    	}
		@include media-breakpoint-up(md) {
			margin: 0 30px 0 0;
			display: inline-block;
    	}
	}
	
	.SocialMediaShareButton {
	    vertical-align: top;
	    display: inline-block;
	    margin-right: 15px;
	    text-align: center;
	    &:hover {
		    cursor: pointer;
	    }
	}
	.share {
		overflow: hidden;
		@include media-breakpoint-up(md) {
		    transform: scale(1.1);
			transform-origin: 100%;
		}
		@include media-breakpoint-up(xl) {
    		transform: scale(1.4);
		    transform-origin: 100%;
    	}
		span {
			display: block;	
			margin-bottom: 1rem;
			@include media-breakpoint-up(md) {
				margin-bottom: 0;
				display: inline-block;
				line-height: 40px; margin-right: 1rem;
				
    		}
    		
    		
    		

    		
		}
	}
	
	&.ResultDark {
		color: $blue;
		
		&:before {
		background: rgba($white, 0.25);
		}
			
		
		.decision-left {
 
		    div {
			    border-bottom: solid 3px $blue;
			    @include media-breakpoint-up(md) {
					border-right: solid 3px $blue;
				}
			} 
	    }
	    
	     .decision-right {
			@include media-breakpoint-up(md) {
				div.decision-inner {
					border-top: solid 3px $blue;
				}
		    }
	    }
	}
}

.Res{
	footer.page-footer .step-indicator {
		display: none;
	}
}

.FunnyFunnyWrap {
	background-image: url(./media/results/funny-funny.jpg);
	@include media-breakpoint-up(lg) {
		background-image: url(./media/results/funny-funny@2x.jpg);		
	}
}

.FunnyRelaxWrap {
	background-image: url(./media/results/funny-relax.jpg);
	background-position: 72% center;
	@include media-breakpoint-up(lg) {
		background-image: url(./media/results/funny-relax@2x.jpg);
	}
}

.FunnyRomanticWrap {
	background-image: url(./media/results/funny-romantic.jpg);
	background-position: 82% center;
	@include media-breakpoint-up(lg) {
		background-image: url(./media/results/funny-romantic@2x.jpg);
	}
}

.RelaxFunnyWrap {
	background-image: url(./media/results/relax-funny.jpg);
	@include media-breakpoint-up(lg) {
		background-image: url(./media/results/relax-funny@2x.jpg);
	}
}

.RelaxRelaxWrap {
	background-image: url(./media/results/relax-relax.jpg);
	@include media-breakpoint-up(lg) {
		background-image: url(./media/results/relax-relax@2x.jpg);
	}
}

.RelaxRomanticWrap {
	background-image: url(./media/results/relax-romantic.jpg);
	@include media-breakpoint-up(lg) {
		background-image: url(./media/results/relax-romantic@2x.jpg);
	}
}

.RomanticRomanticWrap {
	background-image: url(./media/results/romantic-romantic.jpg);
	@include media-breakpoint-up(lg) {
		background-image: url(./media/results/romantic-romantic@2x.jpg);				
	}
}

.RomanticFunnyWrap {
	background-image: url(./media/results/romantic-funny.jpg);
	@include media-breakpoint-up(lg) {
		background-image: url(./media/results/romantic-funny@2x.jpg);	
	}
}

.RomanticRelaxWrap {
	background-image: url(./media/results/romantic-relax.jpg);
	background-position: 70% center;
	@include media-breakpoint-up(lg) {
		background-image: url(./media/results/romantic-relax@2x.jpg);				
	}
}