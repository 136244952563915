.DetectFaceWrap {
	background: url(./media/bg-sea.jpg);
	position: relative;
	min-height: calc(100vh - 65px - 45px);
	@include media-breakpoint-up(lg) {
		background-image: url(./media/bg-sea@2x.jpg);
    }
	
	background-size: cover;
	background-position: center center;
	overflow: hidden;
	.Instructions {
		min-height: 100vh;
		padding-top: 5vh;
		@include media-breakpoint-up(md) {
			padding-bottom: 10vh;
    	}	

		.step {
			margin-top: 2rem;
			@include media-breakpoint-up(md) {
				margin-top: 10vh;
				margin-bottom: 10vh;
    		}
    		> div {
	    		max-width: 300px;
	    		margin: auto; 
    		}
    		
    		img {
	    		height: 40px;
	    		width: auto;
	    		@include media-breakpoint-up(xl) {
					height: 80px;
    			}
    		}
			
			p {
				margin-top: 1rem;
				color: $white;
				text-transform: uppercase;
				@include media-breakpoint-up(xl) {
				font-size: 1.2em;
    			}
			}
		}
		
		.btn {
			margin-top: 2rem;
			&:hover {
				cursor: pointer;
			}
		}
	}
	
	.FaceDetect {
		min-height: 100vh;
		
		.video-container {
			position: relative;
			padding-top: 100%;
			background-color: rgba($white, 0.6);
			border-radius: 20px;
			overflow: hidden;
			margin-bottom: 5vh;
			&::before {
				content: "";
				display: block;
				background-color: rgba($white, 0.2);
				position: absolute;
				z-index: 2;
				top:0;
				left: 0;
				right: 0;
				bottom: 0;
			}
			&:after {
				content: "";
				display: block;
				position: absolute;
				z-index: 2;
				top: 10px;
				left: 10px;
				right: 10px;
				bottom: 10px;
				border-radius: 20px;
				border: solid 2px $blue;	
				background: url(./media/face-overlay.svg);
				background-size: 67% 88%;
			    background-position: center;
			    background-repeat: no-repeat;

			}
			video {
				object-fit: cover;
				position: absolute;
				z-index: 1;
				height: 100%;
				width: 100%;
				top:0;
				left: 0;
				bottom: 0;
				right: 0;
				transform: rotateY(180deg);
			    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
			    -moz-transform:rotateY(180deg); /* Firefox */

				
				
				
			}	
		}

		p {
			text-transform: uppercase;
			font-weight: 900;
			margin-bottom: 0;
		}
		
	}

	.Instructions + footer.page-footer .step-indicator span.indicator-2 {
			background-image: url(./media/indicator-active@2x.png);
	}
	.FaceDetect + footer.page-footer .step-indicator span.indicator-3 {
			background-image: url(./media/indicator-active@2x.png);
	}
	
}