.TestWrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  video {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $black;
  }

  footer.page-footer {
    .step-indicator {
      display: none;
    }
    z-index: 100;
    position: absolute;
    margin: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: linear-gradient(rgba($blue, 0) 0%, rgba($blue, 0.5) 100%);
  }
}
